import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Icon } from "~/components/elements/Icon";
import { Section } from "~/components/elements/Section";
import { Button, ButtonType, ButtonSize } from "~/components/elements/buttons/Button"
import Socials from "~/components/elements/Socials";

export default function Footer({ data, footerCtaType, customFooterCta }) {

  const {
    footer: { footerCta, footerPromoBar, footerLegalMenu, footerMenus, footerCopyright, footerSocialMenu },
  } = data;

  return (
    <footer role="contentinfo" className="">
      <FooterCta {...footerCta} footerCtaType={footerCtaType} customFooterCta={customFooterCta} />
      <FooterPromoBar promoBar={footerPromoBar} />
      <div className="section section--ptop section--pbot bg-navy-dark text-white" data-layout={'footer'}>
        <div className="container">
          <div className="space-y-16 lg:space-y-[140px]">
            <Nav menus={footerMenus} />
            <LegalMenu copyright={footerCopyright} menu={footerLegalMenu} socialMenu={footerSocialMenu} />
          </div>
        </div>
      </div>
    </footer>
  );
}

export const FooterCta = ({section, heading, link, footerCtaType, customFooterCta}) => {

  let ctaHeading = heading
  let ctaLink = link

  if(footerCtaType === 'custom' && customFooterCta){
    ctaHeading = customFooterCta.heading ? customFooterCta.heading : ctaHeading
    ctaLink = customFooterCta.link ? customFooterCta.link : ctaLink
  }

  return (
    <div className="relative">
      <StaticImage src={'../../assets/images/footer-mask.png'} className="block w-full mb-[-5px]" alt="" />
      <StaticImage src={'../../assets/images/footer-cta-dots.png'} className="block w-1/4 !absolute bottom-0 right-0 z-10" alt="" />
      <Section {...section} layoutName={'FooterCta'}>
        <div className="container">
          <div className="max-w-[760px] space-y-6 md:space-y-10">
            <h1 className="text-h1-large text-footer-cta !leading-[1] font-normal" dangerouslySetInnerHTML={{__html: ctaHeading}} />
            <Button link={ctaLink.link} type={ctaLink.type} />
          </div>
        </div>
      </Section>
    </div>
  )
}

export const FooterPromoBar = ({promoBar = ''}) => {
  return (
    <div className="bg-navy text-white text-[17px] py-5 px-6 text-center">
      <div className="footer-promo-bar " dangerouslySetInnerHTML={{__html: promoBar}} />
    </div>
  )
}

export const Nav = ({ menus }) => {
  return (
    <nav className="grid gap-x grid-cols-2 md:grid-cols-3 gap-y-16 lg:gap-y-0 lg:grid-cols-5">
      {menus.map((menu, i) => (
        <div className="space-y-6" key={`footermenu${i}`}>
          <h6 className="text-h6 text-h6--moranga font-light" dangerouslySetInnerHTML={{ __html: menu.heading }} />
          <ul key={`footermenu${i}`} className="space-y-1.5">
            {menu.links.map((link, i) => (
              <li key={`footermenulink${i}`}>
                <Link to={link.link.url} target={link.link.target} className="text-[17px] opacity-80 transition-opacity duration-300 ease-in-out hover:opacity-100">{link.link.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  )
}

export const LegalMenu = ({ copyright, menu, socialMenu }) => {
  return (
    <div className="flex flex-col items-center md:items-stretch  lg:flex-row">
      <div className="lg:w-1/2 order-1 lg:order-[0] mt-16 lg:mt-0 lg:order-0 flex flex-col lg:flex-row items-center lg:space-x-4 space-y-8 lg:space-y-0">
        <div className="w-28">
          <Logo />
        </div>
        <div className="text-[15px]">
          {copyright} {new Date().getFullYear()}
        </div>
      </div>
      <div>
        <Socials menu={socialMenu} />
      </div>
      <div className="mt-16 lg:mt-0 lg:w-1/2 flex items-center justify-center lg:justify-end">
        <div className="space-x-7">
          {menu.map((item, i) => (
            <Link key={`legalmenuitem${i}`} to={item.link.url} target={item.link.target} className="text-[17px] opacity-80 transition-opacity duration-300 ease-in-out hover:opacity-100">
              {item.link.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export const Logo = () => {
  return (
    <svg width="459" height="114" viewBox="0 0 459 114" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-auto">
      <path d="M394.606 81.489L391.243 100.583C390.58 104.336 389.064 106.635 385.559 106.682C382.386 106.635 380.396 104.664 381.107 100.583L384.47 81.489H375.896L372.439 100.583C370.923 108.98 375.849 114 384.754 114C393.328 114 398.301 109.356 399.864 100.583L403.275 81.489H394.606Z" fill="#FDFFFA"/>
      <path d="M414.248 90.4964C414.295 88.8544 416 88.01 417.895 88.01C420.642 88.01 421.732 89.7927 421.684 91.8569H430.353C430.353 85.4297 426.8 81.0668 418.7 81.0668C410.98 81.0668 405.816 85.0075 405.722 91.0124C405.438 102.928 420.169 98.7062 420.027 104.43C420.027 106.212 418.464 107.057 416 107.057C413.016 107.057 411.501 105.837 411.501 103.773H402.88C402.88 110.481 407.474 114 415.195 114C423.011 114 428.268 110.341 428.363 103.82C428.647 91.763 414.153 95.6099 414.248 90.4964Z" fill="#FDFFFA"/>
      <path d="M450.154 113.672H458.443L453.706 81.489H443.427L427.228 113.672H436.18L438.833 107.761H449.585L450.154 113.672ZM441.722 101.333L447.69 88.1038L448.969 101.333H441.722Z" fill="#FDFFFA"/>
      <path d="M394.606 81.489L391.243 100.583C390.58 104.336 389.064 106.635 385.559 106.682C382.386 106.635 380.396 104.664 381.107 100.583L384.47 81.489H375.896L372.439 100.583C370.923 108.98 375.849 114 384.754 114C393.328 114 398.301 109.356 399.864 100.583L403.275 81.489H394.606Z" fill="white"/>
      <path d="M414.248 90.4964C414.295 88.8544 416 88.01 417.895 88.01C420.642 88.01 421.732 89.7927 421.684 91.8569H430.353C430.353 85.4297 426.8 81.0668 418.7 81.0668C410.98 81.0668 405.816 85.0075 405.722 91.0124C405.438 102.928 420.169 98.7062 420.027 104.43C420.027 106.212 418.464 107.057 416 107.057C413.016 107.057 411.501 105.837 411.501 103.773H402.88C402.88 110.481 407.474 114 415.195 114C423.011 114 428.268 110.341 428.363 103.82C428.647 91.763 414.153 95.6099 414.248 90.4964Z" fill="white"/>
      <path d="M450.154 113.672H458.443L453.706 81.489H443.427L427.228 113.672H436.18L438.833 107.761H449.585L450.154 113.672ZM441.722 101.333L447.69 88.1038L448.969 101.333H441.722Z" fill="white"/>
      <path d="M104.667 13.489V46.4903C104.667 54.8804 100.432 59.2753 92.8413 59.2753C85.1703 59.2753 80.9353 54.8804 80.9353 46.4903V13.489H69.9082V46.4903C69.9082 60.394 78.2984 68.7841 92.8413 68.7841C106.905 68.7841 115.694 60.394 115.694 46.4903V13.489H104.667Z" fill="white"/>
      <path d="M162.91 13.489V51.844L137.82 13.489H127.512V68.3047H138.299V30.1095L163.31 68.3047H173.698V13.489H162.91Z" fill="white"/>
      <path d="M209.206 59.1954C200.736 59.1954 194.343 52.4033 194.343 40.8969C194.263 29.5502 200.736 22.5184 209.126 22.5184C217.916 22.5184 221.831 27.7123 222.79 33.7852H233.657C232.459 21.8792 224.708 12.9297 208.886 12.9297C194.024 12.9297 183.156 24.0366 183.156 40.8169C183.156 57.837 193.864 68.7841 208.886 68.7841C224.947 68.7841 232.379 59.6748 233.657 48.0085H222.79C221.911 54.0814 218.155 59.1954 209.206 59.1954Z" fill="white"/>
      <path d="M277.614 13.489V35.5431H253.482V13.489H242.455V68.3047H253.482V45.2118H277.614V68.3047H288.641V13.489H277.614Z" fill="white"/>
      <path d="M311.954 58.636V13.489H300.927V68.3047H338.802V58.636H311.954Z" fill="white"/>
      <path d="M345.796 68.3047H356.823V13.489H345.796V68.3047Z" fill="white"/>
      <path d="M377.916 28.4315C377.916 24.3563 380.952 22.1189 387.584 22.1189C393.737 22.1189 397.253 24.4362 397.333 29.6301H408.36C408.36 19.1624 400.449 12.9297 387.744 12.9297C375.279 12.9297 366.889 19.3222 366.889 28.6712C366.889 50.4856 398.132 40.6571 398.132 53.2024C398.132 57.3575 394.376 59.5949 387.824 59.5949C380.553 59.5949 376.797 56.8781 376.797 51.9239H365.77C365.77 62.7912 373.681 68.7841 387.265 68.7841C400.29 68.7841 409.079 62.7912 409.079 53.2024C409.079 30.589 377.916 40.4174 377.916 28.4315Z" fill="white"/>
      <path d="M429.493 68.3047H440.52V22.8381H457.859V13.489H412.153V22.8381H429.493V68.3047Z" fill="white"/>
      <path d="M41.8376 13.359V22.825H42.8687C48.3338 22.825 51.2211 26.2484 51.2211 31.0742C51.2211 35.9 48.3338 39.4472 42.8687 39.4472H33.0103L30.9546 48.4594H42.8893C54.7579 48.4594 62.1719 42.1591 62.1719 31.002C62.1719 19.8449 53.6958 13.359 41.8376 13.359Z" fill="white"/>
      <path d="M18.7732 29.0295L18.2121 29.0116L17.0898 29.0116C16.3682 21.4151 12.2725 21.3144 12.0396 21.3298C12.0008 21.322 8.77146 21.0349 2.63363 26.7848L1.69475 27.6616L2.19135 26.4821C7.74716 13.2445 18.9907 13.0969 19.0992 13.0971L28.5812 13.0971C28.9847 13.0971 29.3261 13.3764 29.4038 13.7489C29.5512 14.455 29.9935 15.2853 31.3048 15.2931C32.5929 15.2931 33.043 14.4861 33.1904 13.7955C33.2757 13.3843 33.6482 13.0894 34.075 13.0972L37.9546 13.0972C38.8237 13.0972 40.4377 13.6093 40.4454 15.5569L40.4532 21.0583C40.4455 21.8576 40.1739 22.4085 39.7937 22.7887C39.2427 23.3396 38.4668 23.5258 37.9625 23.518L34.2302 23.5258C33.7258 23.518 33.2991 23.1844 33.1594 22.6877C32.9886 22.067 32.5231 21.3221 31.2738 21.3298C31.2661 21.3221 29.9935 21.1979 28.7054 22.2377C27.2389 23.4249 26.3388 25.613 26.0362 28.709L26.0362 28.7401C26.0284 28.7478 26.0284 28.7478 26.0284 28.7634L26.0052 29.0039L18.8587 29.0116L18.7732 29.0295Z" fill="white"/>
      <path d="M16.9805 28.9658H25.9699V68.3578C25.9699 68.6677 25.7187 68.9189 25.4088 68.9189H17.5416C17.2317 68.9189 16.9805 68.6677 16.9805 68.3578V28.9658Z" fill="white"/>
    </svg>
  )
}
