import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import { WpPage_Page_FlexibleContent_TwoColumn_Section } from "~/_generated/types";

export interface SectionProps
  extends WpPage_Page_FlexibleContent_TwoColumn_Section {
  layoutName?: string;
  children?: React.ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  previousSection?: SectionProps;
  nextSection?: SectionProps;
  backgroundColour?: string;
}

export const Section = (props: SectionProps) => {
  const {
    children,
    isFirst,
    isLast,
    previousSection,
    nextSection,
    layoutName = "default",
    id,
    background,
    borderTop,
    borderBottom
  } = props;
  const className = props.sectionclass ? props.sectionclass : '';

  const paddingClasses = getSectionPaddingClasses(
    background,
    layoutName,
    previousSection,
    nextSection
  );

  const colourClasses = getSectionColourClasses(background);
  const layoutSpecificClasses = getLayoutSpecificClasses(layoutName);

  return (
    <section
      id={id}
      data-layout={layoutName}
      className={`${(borderTop === 'painted') ? 'painted painted--top' : ''} ${(borderBottom === 'painted') ? 'painted painted--bottom' : ''} ${className} `}
    >
      {borderTop === 'painted' && (
        <>
          {background === 'blue' ? (
            <StaticImage src={'../../assets/images/painted-border-top-blue.png'} className="block w-full -mb-1" alt="" />
          ) : background === 'navy' ? (
            <StaticImage src={'../../assets/images/painted-border-top-navy.png'} className="block w-full -mb-1 " alt="" />
          ) : background === 'orange' ? (
            <StaticImage src={'../../assets/images/painted-border-top-orange.png'} className="block w-full -mb-1" alt="" />
          ) : background === 'lime' ? (
            <StaticImage src={'../../assets/images/painted-border-top-lime.png'} className="block w-full -mb-1" alt="" />
          ) : null }
        </>
      )}

      <div className={`section ${layoutSpecificClasses}  ${paddingClasses} ${colourClasses} `}>
        {children}
      </div>


      {borderBottom === 'painted' && (
        <>
          {background === 'white' ? (
            <StaticImage src={'../../assets/images/painted-border-bottom-white.png'} className="block w-full -mt-1" alt="" />
          ) : background === 'navy-dark' ? (
            <StaticImage src={'../../assets/images/painted-border-bottom-navy.png'} className="block w-full -mt-1" alt="" />
          ) : null }
        </>
      )}
    </section>
  );
};

// Layout specific classes

function getLayoutSpecificClasses(layoutName: string) {
  let layoutSpecificClasses = "";
  return layoutSpecificClasses;
}

// Helper function to decide padding amounts on sections

function getSectionPaddingClasses(
  background: string,
  layoutName: string,
  previousSection: SectionProps,
  nextSection: SectionProps
) {
  const previousBackground =
    previousSection && previousSection.background
      ? previousSection.background
      : null;
  const nextBackground =
    nextSection && nextSection.background
      ? nextSection.background
      : null;

  const previousName =
    previousSection && previousSection.layoutName
      ? previousSection.layoutName
      : null;
  const nextName =
    nextSection && nextSection.layoutName ? nextSection.layoutName : null;

  let topPaddingClass = "";
  let bottomPaddingClass = "";

  if (previousBackground !== background) {
    topPaddingClass = "section--ptop";
  } else {
    topPaddingClass = "section--no-margin-top";
  }

  if (nextBackground !== background) {
    bottomPaddingClass = "section--pbot";
  } else {
    bottomPaddingClass = "section--no-margin-bots";
  }
  
  return `${topPaddingClass} ${bottomPaddingClass}`;
}

function getSectionColourClasses(backgroundColour: string) {
  let colourClasses = "";
  
  switch (backgroundColour) {
    case "black":
      colourClasses = "bg-black text-white";
      break;
    case "white":
      colourClasses = "bg-white-off";
      break;
    case "grey":
      colourClasses = "bg-grey";
      break;
    case "light-grey":
      colourClasses = "bg-grey-light";
      break;
    case "blue":
      colourClasses = "bg-blue text-white";
      break;
    case "light-blue":
      colourClasses = "bg-[#99EDF8] bg-opacity-30";
      break;
    case "navy":
      colourClasses = "bg-navy text-white";
      break;
    case "navy-dark":
      colourClasses = "bg-navy-dark text-white";
      break;
    case "teal":
      colourClasses = "bg-teal";
      break;
    case "light-teal":
      colourClasses = "bg-teal-light";
      break;
    case "orange":
      colourClasses = "bg-orange";
      break;
    case "light-orange":
      colourClasses = "bg-[#FFD874] bg-opacity-30";
      break;
    case "blue-30":
      colourClasses = "bg-[#99EDF8] bg-opacity-30";
      break;
    case "beige":
      colourClasses = "bg-beige";
      break;
    case "aero":
      colourClasses = "bg-aero";
      break;
    case "light-aero":
      colourClasses = "bg-aero bg-opacity-30";
      break;
    case "lime":
      colourClasses = "bg-lime";
      break;
    case "light-lime":
      colourClasses = "bg-lime bg-opacity-30";
      break;
  }
  return colourClasses;
}
