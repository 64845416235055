import { AnimatePresence, motion } from "framer-motion";
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import Image from '~/components/elements/Image';
import { Button, ButtonType, ButtonSize, ButtonColor } from "~/components/elements/buttons/Button"
import useDocumentScrollThrottled from "~/hooks/useDocumentScrollThrottled";
import { Icon, IconType } from "~/components/elements/Icon";
import { StaticImage } from "gatsby-plugin-image";
import { useCookies } from "react-cookie";

export default function Header({ data, headerColour }) {

  const {
    header: { headerPromoBar, headerMenu, headerLinks },
  } = data;

  const [activeMenu, setActiveMenu] = useState()

  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['hide-promo-bar']);

  const MINIMUM_SCROLL = 10;

  useDocumentScrollThrottled((callbackData) => {
    if (typeof window === "undefined") return;

    const { previousScrollTop, currentScrollTop } = callbackData;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setIsScrolled(isMinimumScrolled);

  });

  let colourClasses = '';
  let logoFill = '#3A66FF'
  let usaFill = '#2E2D65'

  switch (headerColour) {
    case "white":
      colourClasses = "bg-white-off";
      break;
    case "grey-light":
      colourClasses = "bg-grey-light";
      break;
    case "blue":
      colourClasses = "bg-blue text-white";
      logoFill = '#FFFFFF';
      break;
    case "navy":
      colourClasses = "bg-navy text-white";
      logoFill = '#FFFFFF';
      usaFill = '#3A66FF';
      break;
    case "navy-dark":
      colourClasses = "bg-navy-dark text-white";
      logoFill = '#FFFFFF';
      usaFill = '#3A66FF';
      break;
  }

  return (
    <>
      <header

        className={`sticky top-0 left-0 right-0 z-30 transition-all duration-300 ease-in-out ${colourClasses} ${(isScrolled || isHovered) ? 'shadow-sm !bg-white-off text-grey-dark text' : ''}`}
        role="banner">
        <div>
          {headerPromoBar?.link && cookies["hide-promo-bar"] !== 'true' && (
            <PromoBar headerPromoBar={headerPromoBar} headerColour={headerColour} setCookie={setCookie} />
          )}
        </div>
        <div onMouseLeave={() => setIsHovered(false)} onMouseEnter={() => setIsHovered(true)}>
          <div className="container container--wide py-3" >
            <div className="flex items-center">
              <div className="w-1/2">
                <Link to="/" className="w-[123px] block">
                  <Logo fill={logoFill} usaFill={usaFill} isScrolled={isScrolled} isHovered={isHovered} />
                </Link>
              </div>
              <div className="flex-auto w-full flex justify-center">
                <Menu menu={headerMenu} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
              </div>
              <div className="w-1/2">
                <Links links={headerLinks} headerColour={headerColour} />
              </div>
              <div className="ml-auto block lg:hidden">
                <button onClick={() => setNavIsOpen(!!!navIsOpen)} className={`flex items-center ${navIsOpen && 'active'}`}>
                  <div className={`burger`}>
                    <span className="sr-only">Open menu</span>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <MobileNav headerMenu={headerMenu} headerLinks={headerLinks} navIsOpen={navIsOpen} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
      </header>

    </>
  );
}

export const PromoBar = ({ headerPromoBar, headerColour, setCookie }) => {
  return (
    <div className={`${headerColour === 'navy-dark' ? 'bg-blue' : 'bg-navy-dark'} flex items-center text-white text-center  relative overflow-hidden`}>
      <div className="container container--wide py-3">
        <Link to={headerPromoBar.link.url} dangerouslySetInnerHTML={{ __html: headerPromoBar.link.title }} target={headerPromoBar.link.target} className="inline-block text-header-promobar text-[15px] leading-none hover:opacity-70 transition-opacity duration-300 ease-in-out" />
        <button onClick={() => setCookie('hide-promo-bar', true)} className="absolute top-0 bottom-0 right-0 w-12 flex justify-center items-center">
          <div className="w-2.5 h-2.5 fw-icon">
            <Icon type={IconType.Close} />
          </div>
        </button>
      </div>
    </div>
  )
}


export const Logo = ({ fill = '#3A66FF', usaFill = '#2E2D65', isScrolled, isHovered }) => {

  if (isScrolled || isHovered) {
    fill = '#3A66FF'
    usaFill = '#2E2D65'
  }

  return (
    <svg width="121" height="30" viewBox="0 0 1415 352" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1217.55 251.349L1207.18 310.242C1205.14 321.818 1200.46 328.909 1189.65 329.053C1179.86 328.909 1173.72 322.831 1175.92 310.242L1186.29 251.349H1159.84L1149.18 310.242C1144.5 336.144 1159.7 351.627 1187.17 351.627C1213.61 351.627 1228.95 337.301 1233.77 310.242L1244.29 251.349H1217.55Z" fill={usaFill}/>
      <path d="M1278.14 279.131C1278.28 274.067 1283.54 271.462 1289.39 271.462C1297.86 271.462 1301.22 276.961 1301.07 283.328H1327.81C1327.81 263.504 1316.85 250.046 1291.87 250.046C1268.06 250.046 1252.13 262.201 1251.84 280.723C1250.96 317.477 1296.4 304.454 1295.96 322.108C1295.96 327.606 1291.14 330.211 1283.54 330.211C1274.34 330.211 1269.66 326.449 1269.66 320.082H1243.07C1243.07 340.774 1257.24 351.627 1281.06 351.627C1305.17 351.627 1321.38 340.34 1321.68 320.226C1322.55 283.038 1277.85 294.904 1278.14 279.131Z" fill={usaFill}/>
      <path d="M1388.89 350.614H1414.45L1399.84 251.349H1368.14L1318.17 350.614H1345.79L1353.97 332.381H1387.13L1388.89 350.614ZM1362.88 312.557L1381.29 271.752L1385.23 312.557H1362.88Z" fill={usaFill}/>
      <path d="M323.257 41.6071V143.397C323.257 169.276 310.195 182.832 286.78 182.832C263.12 182.832 250.057 169.276 250.057 143.397V41.6071H216.045V143.397C216.045 186.282 241.924 212.161 286.78 212.161C330.158 212.161 357.269 186.282 357.269 143.397V41.6071H323.257Z" fill={fill} />
      <path d="M502.903 41.6071V159.91L425.513 41.6071H393.719V210.682H426.992V92.8718L504.136 210.682H536.176V41.6071H502.903Z" fill={fill} />
      <path d="M645.699 182.585C619.574 182.585 599.856 161.636 599.856 126.145C599.61 91.1466 619.574 69.4576 645.452 69.4576C672.564 69.4576 684.64 85.4779 687.598 104.209H721.117C717.42 67.4859 693.513 39.8818 644.713 39.8818C598.871 39.8818 565.351 74.1405 565.351 125.898C565.351 178.395 598.378 212.161 644.713 212.161C694.253 212.161 717.174 184.064 721.117 148.08H687.598C684.887 166.811 673.303 182.585 645.699 182.585Z" fill={fill} />
      <path d="M856.698 41.6071V109.631H782.266V41.6071H748.253V210.682H782.266V139.454H856.698V210.682H890.71V41.6071H856.698Z" fill={fill} />
      <path d="M962.618 180.86V41.6071H928.605V210.682H1045.43V180.86H962.618Z" fill={fill} />
      <path d="M1067 210.682H1101.01V41.6071H1067V210.682Z" fill={fill} />
      <path d="M1166.07 87.6961C1166.07 75.1263 1175.44 68.2253 1195.89 68.2253C1214.87 68.2253 1225.72 75.3728 1225.96 91.393H1259.98C1259.98 59.1061 1235.58 39.8818 1196.39 39.8818C1157.94 39.8818 1132.06 59.599 1132.06 88.4355C1132.06 155.72 1228.43 125.405 1228.43 164.1C1228.43 176.916 1216.84 183.817 1196.63 183.817C1174.21 183.817 1162.62 175.438 1162.62 160.157H1128.61C1128.61 193.676 1153.01 212.161 1194.91 212.161C1235.08 212.161 1262.19 193.676 1262.19 164.1C1262.19 94.3506 1166.07 124.666 1166.07 87.6961Z" fill={fill} />
      <path d="M1325.16 210.682H1359.17V70.4435H1412.65V41.6071H1271.68V70.4435H1325.16V210.682Z" fill={fill} />
      <path d="M129.464 41.2051V70.4022H132.645C149.501 70.4022 158.407 80.9615 158.407 95.8464C158.407 110.731 149.501 121.672 132.645 121.672H102.237L95.8965 149.47H132.708C169.316 149.47 192.184 130.037 192.184 95.6237C192.184 61.2105 166.04 41.2051 129.464 41.2051Z" fill={fill} />
      <path d="M58.3193 89.5395L56.5885 89.4844L53.1269 89.4844C50.9011 66.0535 38.2683 65.7427 37.5499 65.7902C37.4302 65.7663 27.4695 64.8807 8.53785 82.6158L5.64195 85.3202L7.17369 81.6823C24.3102 40.8516 58.9902 40.3966 59.3247 40.3972L88.5714 40.3972C89.816 40.3972 90.869 41.2587 91.1084 42.4076C91.5632 44.5856 92.9273 47.1464 96.9722 47.1704C100.945 47.1704 102.333 44.6814 102.788 42.5513C103.051 41.2829 104.2 40.3734 105.516 40.3973L117.483 40.3973C120.164 40.3973 125.142 41.9768 125.166 47.9842L125.19 64.953C125.166 67.4181 124.328 69.1173 123.155 70.29C121.456 71.9893 119.063 72.5637 117.507 72.5397L105.995 72.5636C104.44 72.5396 103.123 71.5106 102.692 69.9787C102.166 68.0641 100.73 65.7665 96.8765 65.7904C96.8526 65.7665 92.9275 65.3834 88.9545 68.5906C84.4311 72.2524 81.6549 79.0015 80.7214 88.551L80.7214 88.6467C80.6975 88.6707 80.6975 88.6707 80.6975 88.7185L80.6257 89.4605L58.583 89.4844L58.3193 89.5395Z" fill={fill} />
      <path d="M52.7891 89.3433H80.5164V210.845C80.5164 211.801 79.7415 212.576 78.7856 212.576H54.5198C53.564 212.576 52.7891 211.801 52.7891 210.845V89.3433Z" fill={fill} />
    </svg>
    
  )
}

export const Menu = ({ menu, activeMenu, setActiveMenu }) => {
  return (
    <nav>
      {menu[activeMenu] && menu[activeMenu].subnav !== "none" ? <div onMouseEnter={() => setActiveMenu(null)} className="absolute top-[100%] left-0 right-0 bottom-0 h-[600px]"></div> : null}
      <ul className="hidden lg:flex lg:space-x-6 xl:space-x-8">
        {menu.map((item, i) => (
          <li key={`menuLink${i}`} className="leading-none" >
            <Link onMouseEnter={!item.subnav ? setActiveMenu(null) : null} to={item.link.url} target={item.link.target} activeClassName={item.link.url.includes('become-a-pro') ? 'text-grey-dark' : 'font-medium'} onMouseEnter={() => setActiveMenu(i)} className="transition-opacity duration-300 ease-in-out hover:opacity-50 text-[15px]">{item.link.title}</Link>
            <AnimatePresence>
              {(item.subnav === 'services' && activeMenu === i) ? (
                <Services setActiveMenu={setActiveMenu} {...item} />
              ) : (item.subnav === 'use-cases' && activeMenu === i) && (
                <UseCases setActiveMenu={setActiveMenu} {...item} />
              )}
            </AnimatePresence>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export const Services = ({ services }) => {
  return (
    <motion.nav
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: .3, ease: "easeInOut" }}
      className="lg:absolute top-full left-0 right-0 bg-white lg:shadow-sm">
      <ul className="space-y-2.5 lg:space-y-0 pt-2.5 px-0 container services-nav-list lg:py-12 lg:grid grid-cols-4 lg:gap-x-8">
        {services.map((service, i) => (
          <li key={i}>
            <Link to={service.link.url} target={service.link.target} className="flex items-center space-x-8 py-6 lg:py-0 pl-7 lg:pl-0 pr-4 lg:pr-0 bg-beige lg:bg-transparent lg:space-x-0 lg:block lg:space-y-8 hover:opacity-50 transition-opacity duration-300 ease-in-out">
              <div className="w-[90px] flex-shrink-0">
                <Image image={service.image} />
              </div>
              <div className="space-y-4">
                <h6 className="text-h6 text-h6--moranga !text-navy-dark">{service.link.title}</h6>
                <p className="text-[15px] leading-6">{service.content}</p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <StaticImage src={'../../assets/images/services-nav-bg.png'} className="!hidden lg:!block !absolute right-0 top-1/2 transform -translate-y-1/2 w-24" alt="" />
    </motion.nav>
  )
}

export const UseCases = ({ useCases }) => {
  return (
    <motion.nav
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: .3, ease: "easeInOut" }}
      className="lg:absolute top-full left-0 right-0 bg-white shadow-sm">
      <ul className="space-y-2.5 lg:space-y-0 pt-2.5 px-0 container use-cases-nav-list lg:py-12 lg:grid grid-cols-4 lg:gap-x-8">
        {useCases.map((useCase, i) => (
          <li key={i}>
            <Link to={useCase.link.url} target={useCase.link.target} className="flex items-center ml-auto lg:space-x-0 lg:block bg-beige hover:bg-aero duration-300 ease-in-out transition-colors p-8 lg:pt-10 lg:p-10 text-center lg:space-y-6">
              <div className="w-1/2 lg:w-[100px] lg:mx-auto flex-shrink-0">
                <div className="w-[100px] lg:mx-auto flex-shrink-0">
                  <Image image={useCase.image} />
                </div>
              </div>
              <div className="lg:space-y-2.5 flex items-center space-x-2.5 lg:space-x-0 lg:flex-col">
                <div className="space-y-4">
                  <h6 className="text-h6 text-h6--moranga !text-navy-dark leading-none">{useCase.link.title}</h6>
                </div>
                <div className="flex justify-center">
                  <div className="w-8 h-8 rounded-full bg-blue text-white flex justify-center items-center group-hover:bg-navy transition-colors duration-300 ease-in-out">
                    <div className="w-[14px] fw-icon">
                      <Icon type={IconType.ArrowRight} />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </motion.nav>
  )
}

export const Links = ({ links, headerColour }) => {

  let buttonColor = ButtonColor.Blue

  if (headerColour === 'blue') {
    buttonColor = ButtonColor.Default
  }

  return (
    <div className="hidden lg:flex items-center justify-end space-x-7">
      {links.map((item, i) => (
        <Button key={`headerLinks${i}`} link={item.linkLink.link} type={item.linkLink.type} size={ButtonSize.Small} color={buttonColor} />
      ))}
    </div>
  )
}

export const MobileNav = ({ headerMenu, headerLinks, navIsOpen, activeMenu, setActiveMenu }) => {

  return (
    <AnimatePresence>
      {navIsOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          className="bg-white px-6 text-navy-dark top-0 bottom-0 left-0 right-0  block w-screen h-screen  pb-12 overflow-y-auto lg:hidden z-[50] space-y-12"
        >

          <nav>
            <ul className="">
              {headerMenu.map((item, i) => (
                <li key={`menuLink${i}`} className="leading-none" >
                  <Link to={item.link.url} target={item.link.target} className="flex items-center text-[30px] py-5 pt-6 border-b border-b-black font-moranga font-light" onClick={item.subnav !== 'none' ? (e) => (e.preventDefault(), setActiveMenu(activeMenu === i ? null : i)) : undefined}>
                    {item.link.title}
                    <div className={`w-8 h-8 rounded-full bg-blue text-white ml-auto flex justify-center items-center group-hover:bg-navy transition-all duration-300 ease-in-out ${activeMenu === i && 'bg-black rotate-90'}`}>
                      <div className="w-[14px] fw-icon">
                        <Icon type={IconType.ArrowRight} />
                      </div>
                    </div>
                  </Link>

                  <AnimatePresence>
                    {(item.subnav === 'services' && activeMenu === i) ? (
                      <Services {...item} />
                    ) : (item.subnav === 'use-cases' && activeMenu === i) && (
                      <UseCases {...item} />
                    )}
                  </AnimatePresence>
                </li>
              ))}
            </ul>
          </nav>
          <div className="flex flex-col text-center space-y-2.5">
            {headerLinks.map((item, i) => (
              <div className={`mobile-menu-button flex ${i % 2 !== 0 && 'mobile-menu-button--outline'}`}>
                <Button key={`headerLinks${i}`} link={item.linkLink.link}  type={i % 2 === 0 ? ButtonType.Button : ButtonType.ButtonOutline} color={ButtonColor.Blue} />
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
