import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { Icon, IconType } from './Icon';

export default function Socials({ menu, className = '' }) {

  return (
    <div className={`flex items-center justify-center ${className}`}>
      {menu.map((item, i) => (
        <Link key={`socials${i}`} to={item.link.url} target={item.link.target} className="transition-opacity duration-300 ease-in-out hover:opacity-50 p-3">
          <Icon type={item.icon} />
        </Link>
      ))}
    </div>
  )
}
