import React from "react";

export const ArrowRight = () => (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.300781 4.58798H11.6042V6.04628H0.300781V4.58798Z" fill="currentColor" />
    <path d="M8.47064 10.204L7.44922 9.18256L11.3148 5.31768L7.44922 1.45279L8.47064 0.431366L13.3563 5.31769L8.47064 10.204Z" fill="currentColor" />
  </svg>
);

export const ArrowLeft = () => (
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.749 5.48535L1.7095 5.48535L1.7095 4.0611L12.749 4.0611L12.749 5.48535Z" fill="currentColor" />
    <path d="M4.77195 0.000459758L5.76953 0.998036L1.99419 4.77269L5.76953 8.54734L4.77195 9.54492L0.000388682 4.77267L4.77195 0.000459758Z" fill="currentColor" />
  </svg>
);

export const Facebook = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.9 0.105469H1.1C0.808262 0.105469 0.528473 0.221361 0.322183 0.427651C0.115893 0.633941 0 0.913731 0 1.20547V19.0055C0 19.1499 0.0284524 19.293 0.0837326 19.4264C0.139013 19.5599 0.220038 19.6811 0.322183 19.7833C0.424327 19.8854 0.54559 19.9665 0.679048 20.0217C0.812506 20.077 0.955546 20.1055 1.1 20.1055H10.68V12.3555H8.08V9.35547H10.68V7.10547C10.6261 6.57723 10.6885 6.0436 10.8627 5.54201C11.0369 5.04042 11.3188 4.58301 11.6885 4.20188C12.0582 3.82075 12.5068 3.52511 13.0028 3.33571C13.4989 3.1463 14.0304 3.06772 14.56 3.10547C15.3383 3.10068 16.1163 3.14075 16.89 3.22547V5.92547H15.3C14.04 5.92547 13.8 6.52547 13.8 7.39547V9.32547H16.8L16.41 12.3255H13.8V20.1055H18.9C19.0445 20.1055 19.1875 20.077 19.321 20.0217C19.4544 19.9665 19.5757 19.8854 19.6778 19.7833C19.78 19.6811 19.861 19.5599 19.9163 19.4264C19.9715 19.293 20 19.1499 20 19.0055V1.20547C20 1.06101 19.9715 0.917975 19.9163 0.784517C19.861 0.651059 19.78 0.529796 19.6778 0.427651C19.5757 0.325507 19.4544 0.244482 19.321 0.189201C19.1875 0.133921 19.0445 0.105469 18.9 0.105469Z" fill="currentColor" />
    </svg>
  )
}

export const LinkedIn = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.4696 0.105609H1.52957C1.33915 0.102964 1.15007 0.137856 0.973136 0.208292C0.796201 0.278729 0.634874 0.383329 0.498368 0.516121C0.361862 0.648912 0.252852 0.807293 0.177562 0.982218C0.102273 1.15714 0.0621787 1.34519 0.0595703 1.53561V18.6756C0.0621787 18.866 0.102273 19.0541 0.177562 19.229C0.252852 19.4039 0.361862 19.5623 0.498368 19.6951C0.634874 19.8279 0.796201 19.9325 0.973136 20.0029C1.15007 20.0734 1.33915 20.1083 1.52957 20.1056H18.4696C18.66 20.1083 18.8491 20.0734 19.026 20.0029C19.2029 19.9325 19.3643 19.8279 19.5008 19.6951C19.6373 19.5623 19.7463 19.4039 19.8216 19.229C19.8969 19.0541 19.937 18.866 19.9396 18.6756V1.53561C19.937 1.34519 19.8969 1.15714 19.8216 0.982218C19.7463 0.807293 19.6373 0.648912 19.5008 0.516121C19.3643 0.383329 19.2029 0.278729 19.026 0.208292C18.8491 0.137856 18.66 0.102964 18.4696 0.105609V0.105609ZM6.08957 16.8456H3.08957V7.84561H6.08957V16.8456ZM4.58957 6.58561C4.17583 6.58561 3.77904 6.42125 3.48648 6.1287C3.19393 5.83614 3.02957 5.43935 3.02957 5.02561C3.02957 4.61187 3.19393 4.21508 3.48648 3.92252C3.77904 3.62997 4.17583 3.46561 4.58957 3.46561C4.80927 3.44069 5.03175 3.46246 5.24245 3.52949C5.45314 3.59652 5.64731 3.7073 5.81223 3.85457C5.97715 4.00184 6.1091 4.18229 6.19944 4.38409C6.28979 4.58589 6.33649 4.8045 6.33649 5.02561C6.33649 5.24671 6.28979 5.46532 6.19944 5.66713C6.1091 5.86893 5.97715 6.04938 5.81223 6.19665C5.64731 6.34392 5.45314 6.4547 5.24245 6.52173C5.03175 6.58875 4.80927 6.61052 4.58957 6.58561V6.58561ZM16.9096 16.8456H13.9096V12.0156C13.9096 10.8056 13.4796 10.0156 12.3896 10.0156C12.0522 10.0181 11.7238 10.1239 11.4484 10.3188C11.1731 10.5137 10.9641 10.7883 10.8496 11.1056C10.7713 11.3406 10.7374 11.5882 10.7496 11.8356V16.8356H7.74957C7.74957 16.8356 7.74957 8.65561 7.74957 7.83561H10.7496V9.10561C11.0221 8.63271 11.4185 8.24313 11.896 7.97881C12.3735 7.71449 12.9141 7.58546 13.4596 7.60561C15.4596 7.60561 16.9096 8.89561 16.9096 11.6656V16.8456Z" fill="currentColor" />
    </svg>
  )
}

export const LinkedInLetters = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.45516 11.5488H8.10387V32.9343H1.45516V11.5488ZM4.77969 0.918396C6.9047 0.918396 8.63165 2.64534 8.63165 4.77392C8.63165 6.90035 6.9047 8.6273 4.77969 8.6273C2.64969 8.6273 0.927734 6.90035 0.927734 4.77392C0.927734 2.64534 2.64969 0.918396 4.77969 0.918396Z" fill="currentColor" />
      <path d="M12.2715 11.5488H18.6469V14.4732H18.7377C19.6251 12.7911 21.7939 11.0193 25.0265 11.0193C31.7572 11.0193 32.9995 15.4471 32.9995 21.2053V32.9343H26.3558V22.5345C26.3558 20.0538 26.3127 16.864 22.9019 16.864C19.443 16.864 18.9152 19.5678 18.9152 22.3563V32.9343H12.2715V11.5488Z" fill="currentColor" />
    </svg>
  )
}

export const Twitter = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 2.90568C19.2483 3.23177 18.4534 3.44734 17.64 3.54568C18.4982 3.033 19.1413 2.22646 19.45 1.27568C18.6436 1.75574 17.7608 2.09398 16.84 2.27568C16.2245 1.60825 15.405 1.16397 14.5098 1.01253C13.6147 0.861085 12.6945 1.01104 11.8938 1.43887C11.093 1.8667 10.4569 2.54821 10.0852 3.37651C9.71355 4.20482 9.62729 5.13307 9.84 6.01568C8.20943 5.9332 6.61444 5.50863 5.15865 4.76954C3.70287 4.03045 2.41885 2.99337 1.39 1.72568C1.02914 2.35585 0.839519 3.0695 0.84 3.79568C0.83872 4.47006 1.00422 5.13429 1.32176 5.72924C1.63929 6.32419 2.09902 6.83139 2.66 7.20568C2.00798 7.18794 1.36989 7.01298 0.8 6.69568V6.74568C0.804887 7.69057 1.13599 8.60477 1.73731 9.33364C2.33864 10.0625 3.17326 10.5613 4.1 10.7457C3.74326 10.8542 3.37287 10.9115 3 10.9157C2.74189 10.9127 2.48442 10.8893 2.23 10.8457C2.49391 11.6585 3.00462 12.3688 3.69107 12.8778C4.37753 13.3869 5.20558 13.6692 6.06 13.6857C4.6172 14.8209 2.83588 15.4405 1 15.4457C0.665735 15.4468 0.331736 15.4267 0 15.3857C1.87443 16.5959 4.05881 17.2384 6.29 17.2357C7.82969 17.2517 9.35714 16.9607 10.7831 16.3798C12.2091 15.7988 13.505 14.9396 14.5952 13.8522C15.6854 12.7648 16.548 11.4711 17.1326 10.0466C17.7172 8.6221 18.012 7.09541 18 5.55568C18 5.38568 18 5.20568 18 5.02568C18.7847 4.44049 19.4615 3.7231 20 2.90568Z" fill="currentColor" />
    </svg>
  )
}

export const YouTube = () => {
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23 5.81545C23.0495 4.38411 22.7365 2.96347 22.09 1.68545C21.6514 1.16105 21.0427 0.807163 20.37 0.685453C17.5875 0.432978 14.7936 0.329497 12 0.375453C9.21667 0.327411 6.43274 0.427553 3.66003 0.675453C3.11185 0.77517 2.60454 1.03229 2.20003 1.41545C1.30003 2.24545 1.20003 3.66545 1.10003 4.86545C0.954939 7.02302 0.954939 9.18789 1.10003 11.3455C1.12896 12.0209 1.22952 12.6913 1.40003 13.3455C1.5206 13.8505 1.76455 14.3178 2.11003 14.7055C2.51729 15.1089 3.03641 15.3807 3.60003 15.4855C5.75594 15.7516 7.92824 15.8619 10.1 15.8155C13.6 15.8655 16.67 15.8155 20.3 15.5355C20.8775 15.4371 21.4112 15.165 21.83 14.7555C22.11 14.4754 22.3191 14.1326 22.44 13.7555C22.7977 12.6581 22.9733 11.5096 22.96 10.3555C23 9.79545 23 6.41545 23 5.81545ZM9.74003 10.9555V4.76545L15.66 7.87545C14 8.79545 11.81 9.83545 9.74003 10.9555Z" fill="currentColor" />
    </svg>
  )
}

export const ChevronDown = () => {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.58771 9.68611C5.63605 9.74954 5.69838 9.80095 5.76984 9.83634C5.8413 9.87172 5.91997 9.89014 5.99971 9.89014C6.07946 9.89014 6.15813 9.87172 6.22959 9.83634C6.30105 9.80095 6.36338 9.74954 6.41171 9.68611L11.9117 1.68611C11.9633 1.61104 11.9933 1.52332 11.9987 1.43241C12.004 1.34151 11.9844 1.25087 11.942 1.17029C11.8996 1.0897 11.836 1.02222 11.758 0.975138C11.6801 0.928053 11.5908 0.903149 11.4997 0.903111L0.499715 0.90311C0.408653 0.903148 0.31933 0.928052 0.241386 0.975137C0.163442 1.02222 0.0998371 1.0897 0.0574339 1.17028C0.0150307 1.25087 -0.00456068 1.34151 0.000776077 1.43241C0.00611283 1.52332 0.0361736 1.61104 0.0877149 1.68611L5.58771 9.68611Z" fill="currentColor" />
    </svg>
  )
}

export const ChevronUp = () => {
  return (
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 4L5 1L1 4" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export const Email = () => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6368 11.166C10.2879 11.3445 9.89673 11.438 9.49921 11.438C9.10169 11.438 8.71053 11.3445 8.36158 11.166L0 6.89014V13.6639C0 14.2543 0.250222 14.8206 0.695621 15.2381C1.14102 15.6556 1.74511 15.8901 2.375 15.8901H16.625C17.2549 15.8901 17.859 15.6556 18.3044 15.2381C18.7498 14.8206 19 14.2543 19 13.6639V6.89014L10.6368 11.166Z" fill="currentColor" />
      <path d="M9.5 9.89014C9.36777 9.89005 9.23768 9.8586 9.12158 9.79864L0.41325 5.29864C0.288255 5.23419 0.183905 5.13903 0.111182 5.02314C0.0384592 4.90726 5.12114e-05 4.77495 0 4.64014L0 3.14014C0 2.5434 0.250222 1.9711 0.695621 1.54915C1.14102 1.12719 1.74511 0.890137 2.375 0.890137H16.625C17.2549 0.890137 17.859 1.12719 18.3044 1.54915C18.7498 1.9711 19 2.5434 19 3.14014V4.64014C18.9999 4.77495 18.9615 4.90726 18.8888 5.02314C18.8161 5.13903 18.7117 5.23419 18.5868 5.29864L9.87842 9.79864C9.76232 9.8586 9.63223 9.89005 9.5 9.89014Z" fill="currentColor" />
    </svg>
  )
}

export const Flag = () => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.03906 15.5801L1.03906 1.58008C1.03906 1.02779 1.48678 0.580078 2.03906 0.580078L12.7057 0.580078L7.70573 4.74674L12.7057 8.91341L3.53906 8.91341" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const Tick = () => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.80078 5.69333L4.77221 8.46666L12.2008 1.53333" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  )
}

export const Close = () => {
  return (
    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0.5L10 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 0.5L1 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const Play = () => {
  return (
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5458 12.4328L1.8457 22.0769C1.51148 22.2683 1.10181 22.2683 0.767578 22.0769C0.433356 21.8829 0.228516 21.5271 0.228516 21.1416V1.85958C0.228516 1.47415 0.433356 1.11838 0.767578 0.924285C1.10179 0.732921 1.51147 0.732921 1.8457 0.924285L18.5458 10.5657V10.5684C18.88 10.7597 19.0848 11.1155 19.0848 11.501C19.0848 11.8864 18.88 12.2414 18.5458 12.4328Z" fill="currentColor" />
    </svg>
  )
}

export const Pin = () => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 6.14701C12.5 9.32376 7 15 7 15C7 15 1.5 9.32376 1.5 6.14701C1.5 2.88992 4.34213 1 7 1C9.65788 1 12.5 2.88992 12.5 6.14701Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square" />
    </svg>

  )
}

export const Clock = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 17C13.1926 17 17 13.1926 17 8.5C17 3.80741 13.1926 0 8.5 0C3.80741 0 0 3.80741 0 8.5C0 13.1926 3.80741 17 8.5 17ZM7.51196 4.11612C7.51196 3.7865 7.77968 3.51961 8.10847 3.51961C8.43809 3.51961 8.70498 3.78733 8.70498 4.11612V8.62287L11.1956 10.496C11.4634 10.7018 11.5044 11.0725 11.3195 11.3402C11.1956 11.505 11.0317 11.587 10.8459 11.587C10.7221 11.587 10.5991 11.546 10.4962 11.4632L7.7588 9.4051C7.6149 9.3022 7.512 9.1173 7.512 8.93158L7.51196 4.11612Z" fill="currentColor" />
    </svg>

  )
}

export enum IconType {
  None = "none",
  ArrowRight = "arrowRight",
  ArrowLeft = "arrowLeft",
  Facebook = "facebook",
  LinkedIn = "linkedIn",
  LinkedInLetters = "linkedInLetters",
  Twitter = "twitter",
  YouTube = "youTube",
  ChevronDown = "chevronDown",
  ChevronUp = "chevronUp",
  Email = "email",
  Flag = "flag",
  Tick = "tick",
  Close = "close",
  Play = "play",
  Pin = "pin",
  Clock = "clock",
}

const IconMap = {
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  facebook: Facebook,
  linkedin: LinkedIn,
  linkedInLetters: LinkedInLetters,
  twitter: Twitter,
  youtube: YouTube,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  email: Email,
  flag: Flag,
  tick: Tick,
  close: Close,
  play: Play,
  pin: Pin,
  clock: Clock,
};

interface IconProps {
  type: string;
}

export const Icon = ({ type }: IconProps) => {
  if ((type === IconType.None || !type)) return null;
  return IconMap[type]();
};
