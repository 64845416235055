import React from "react";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import useSiteInfoAssets from "~/hooks/useSiteInfoAssets";
import Footer from "~/components/global/Footer";
import Header from "~/components/global/Header";

export default function Layout({ wp, wpPage, children, headerColor = '' }) {
  const seo = useSiteInfoAssets();

  let color

  if(headerColor){
    color = headerColor
  } else if(wpPage?.page?.page?.headerColour){
    color = wpPage?.page?.page?.headerColour
  }


  return (
    <SEOContext.Provider value={{ global: seo }}>
      <nav id="skip-to">
        <a href="#content">Skip to main content</a>
      </nav>
      <a id="top" href="#top"><div className="screenreader-text">Top of page</div></a>
      <Header data={wp?.header}  headerColour={color} />
      <main role="main" id="content">
        {children}
      </main>
      <Footer data={wp?.footer} footerCtaType={wpPage?.page?.page?.footerCtaType} customFooterCta={wpPage?.page?.page?.footerCta}  />
      <nav id="back-to-top">
        <a href="#top">Back to top</a>
      </nav>
    </SEOContext.Provider>
  );
}
