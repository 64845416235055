import { Link } from "gatsby";
import React from "react";
import { WpAcfLink } from "~/_generated/types";

export enum ButtonType {
  Button = 'button',
  Text = 'text',
  ButtonOutline = 'button-outline',
}

export enum ButtonSize {
  Small = 'small',
}

export enum ButtonColor {
  Default = 'default',
  Blue = 'blue',
  Black = 'black'
}

export interface ButtonProps {
  link: WpAcfLink;
  type: ButtonType;
  size: ButtonSize;
  color: ButtonColor;
}

export const Button = ({ link, type, size, color  }: ButtonProps) => {
  if (!link) return null;

  const Tag = link?.url?.length > 0 ? Link : 'button';

  let typeClasses = "";
  if (type === ButtonType.Button) {
    typeClasses = "button";
    if(size === ButtonSize.Small) {
      typeClasses = typeClasses + ' button--small';
    }
    if(color === ButtonColor.Blue) {
      typeClasses = typeClasses + ' button--blue';
    }
  } else if (type === ButtonType.Text) {
    typeClasses = "text-link";
    if(color === ButtonColor.Black) {
      typeClasses = typeClasses + ' text-link--black';
    }
  }  else if (type === ButtonType.ButtonOutline) {
    typeClasses = "button button--outline";
  } else {
    typeClasses = "hover:opacity-50 transition-opacity duration-300 ease-in-out";
    
  }

  return (
    <Tag
      to={link?.url || "#"}
      target={link?.target}
      className={`whitespace-nowrap ${typeClasses}`}
    >
      <span className="">{link?.title}</span>
      {type && (
        <span className="" aria-hidden={true}>{link?.title}</span>
      )}
    </Tag>
  );
};
